import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import styles from './cart-item-registration.module.scss';

const CartItemRegistartion = ({
  name,
  price,
  descriptionPrimary,
  description,
  icon,
  bgColor,
  color,
  ibpFluid,
  imageUrl,
}) => (
    <div className={styles.item}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt={name} />
      ) : ibpFluid ? (
        <div className={styles.ibpImage}>
          <Img fluid={ibpFluid} />
        </div>
      ) : (
        <div className={styles.image} style={{ color, backgroundColor: bgColor }}>
          <i className={`icon-${icon}`} />
        </div>
      )}

      <div className={styles.info}>
        <p className={styles.name}>{name}</p>
        {descriptionPrimary ? (
          <p className={styles.descriptionPrimary}>{descriptionPrimary}</p>
        ) : null}
        {description ? <p className={styles.description}>{description}</p> : null}
      </div>

      <div className={styles.price}>{`${price}$`}</div>
    </div>
  );

CartItemRegistartion.defaultProps = {
  descriptionPrimary: null,
  description: null,
  icon: '',
};

CartItemRegistartion.propTypes = {
  name: PropTypes.string.isRequired,
  descriptionPrimary: PropTypes.string,
  description: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ibpFluid: PropTypes.object.isRequired,
};

export default CartItemRegistartion;
